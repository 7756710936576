<template>
  <div class="column pt-0">
    <h2 class="is-size-5 has-text-weight-semibold mb-4">Список филиалов</h2>
    <BaseTable
      :data="branches"
      :columns="columns"
      :loading="isLoading"
      :total="total"
      :per-page="perPage"
      :current-page="currentPage"
      @change="fetchBranches"
      @rowClick="onRowClick"
    />
  </div>
</template>


<script>
import BaseTable from "@/components/Base/Table";

export default {
  components: {
    BaseTable,
  },
  data() {
    return {
      total: 0,
      branches: [],
      isLoading: true,
      isActive: false,
      currentPage: 1,
      perPage: 10,
      columns: [
        {
          field: "id",
          label: "ID",
          width: "40",
          numeric: true,
        },
        {
          field: "company.name",
          label: "Компания",
          centered: true,
        },
        {
          field: "name",
          label: "Филиал",
        },
      ],
    };
  },
  mounted() {
    this.fetchBranches();
  },
  methods: {
    async fetchBranches(page) {
      this.currentPage = page || 1
      this.isLoading = true;
      try {
        const { data } = await this.$axios.get(`admin/branches/paginate/${(this.currentPage - 1) * 10}/${this.perPage}`)
        this.branches = data?.data || [];
        this.total = data?.count;
      } catch (e) {
        throw new Error(e)
      }
      this.isLoading = false;
    },
    onRowClick(item) {
      this.$router.push(`/filial-editor/${item.id}`);
    },
  },
};
</script>

